.chat {
  --#{$prefix}chat-bottom-gap: 4rem;
  @include media-breakpoint-up(sm) {
    --#{$prefix}chat-bottom-gap: 6rem;
  }
  height: calc(
    100vh -
      (
        var(--#{$prefix}navbar-top-height) + var(--#{$prefix}footer-height) +
          var(--#{$prefix}chat-bottom-gap)
      )
  );
  .chat-sidebar {
    // position: absolute;
    // top: 0;
    flex-shrink: 0;
    width: 100%;
    @include media-breakpoint-up(sm) {
      width: auto;
      background: transparent;
      border: 0;
      position: static;
      transform: none;
    }
    @include media-breakpoint-up(xl) {
      width: 360px;
      min-width: 360px;
      position: static;
      transform: none;
    }
  }
  .chat-conversation-tab {
    .nav-item {
      .nav-link {
        &.active {
          background: var(--#{$prefix}200);
          border-radius: 6px;
        }
        &.unread {
          .name {
            color: var(--#{$prefix}1100) !important;
            font-weight: $font-weight-bold !important;
          }
          .message {
            color: var(--#{$prefix}800) !important;
            font-weight: $font-weight-semi-bold !important;
          }
        }
      }
    }
  }
  .chat-message {
    .actions {
      box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.12),
        0px 8px 24px -4px rgba(36, 40, 46, 0.12);
    }
    .chat-message-content {
      max-width: calc(100% - 4rem);
      &.received {
        max-width: calc(100% - 6rem);
      }
      // .attachments {
      img {
        // max-width: min(100%, 200px);
        width: 100%;
        max-width: 200px;
      }
      // }
      @include media-breakpoint-up(sm) {
        max-width: calc(100% - 10rem);
        &.received {
          max-width: calc(100% - 11rem);
        }
      }
    }

    .received-message-content {
      position: relative;
      border-radius: $border-radius;
      padding: map-get($spacers, 3);
      &:not(&.attachments) {
        &:after {
          content: ' ';
          position: absolute;
          width: 0;
          height: 0;
          left: -10px;
          right: auto;
          top: 0px;
          bottom: auto;
          border: 10px solid;
          border-color: var(--#{$prefix}white) transparent transparent
            transparent;
        }
        &:before {
          content: ' ';
          position: absolute;
          width: 0;
          height: 0;
          left: -12px;
          right: auto;
          top: -1px;
          bottom: auto;
          border: 11px solid;
          border-color: var(--#{$prefix}200) transparent transparent transparent;
        }
      }
    }
    .sent-message-content {
      position: relative;
      &:not(&.attachments) {
        border-radius: $border-radius;
        padding: map-get($spacers, 3);
        color: var(--#{$prefix}white);
        background: var(--#{$prefix}primary);
        &:after {
          content: ' ';
          position: absolute;
          width: 0;
          height: 0;
          left: auto;
          right: -12px;
          top: auto;
          bottom: 0;
          border: 12px solid;
          border-color: transparent transparent var(--#{$prefix}primary)
            transparent;
        }
      }
    }
  }
  .chat-textarea {
    max-height: 6.25rem;
    &[contenteditable='true']:empty:before {
      content: attr(placeholder);
      pointer-events: none;
      color: var(--#{$prefix}gray-500);
      font-weight: $font-weight-semi-bold;
    }
  }
}

.chat-search-box {
  box-shadow: 0px 16px 80px -4px rgba(36, 40, 46, 0.3);
  .form-icon {
    top: 1.25rem !important;
  }
}

#searchBoxModal {
  .modal-content {
    background-color: transparent;
  }
  .modal-backdrop {
    background-color: transparent;
  }
}
