/*-----------------------------------------------
|   Dropdown
-----------------------------------------------*/
.dropdown-menu {
  font-size: map_get($font-sizes, '9');
  // border-radius: $border-radius-lg;
}

.dropdown-caret-none {
  &:after {
    display: none;
  }
}

.dropdown-md {
  min-width: 16.625rem;
}

.avatar-dropdown-menu {
  width: 320px;
}
