.react-select-container {
  position: relative;
  .react-select-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
.react-select__control {
  min-height: unset !important;
  padding: 0.5rem 1rem;
  border-radius: $input-border-radius !important;
  &.react-select__control--is-focused {
    border-color: $form-select-focus-border-color !important;
    outline: 0;
    box-shadow: $form-select-focus-box-shadow !important;
  }
  background-color: $input-bg !important;
  border: $form-select-border-width solid $form-select-border-color !important;
  @include box-shadow($form-select-box-shadow);

  .react-select__value-container {
    padding: 0;
  }

  .react-select__placeholder {
    color: $input-placeholder-color;
    font-size: map-get($font-sizes, '9');
    font-weight: $font-weight-semi-bold;
    margin: 0;
  }
  @include hover-focus {
    outline: none !important;
  }
  .react-select__input-container {
    margin: 0;
    padding: 0;
    line-height: 1;
    font-size: map-get($font-sizes, '10');
  }
  .react-select__value-container {
    &.react-select__value-container--has-value {
      padding-top: 4px;
      padding-bottom: 4px;
      padding: 0;
    }
  }
  .react-select__multi-value {
    text-transform: uppercase;
    padding: 2.5px 8px !important;
    align-items: center;
    border: 0;
    background-color: var(--#{$prefix}choices-item-bg) !important;
    border-radius: 4px;
    margin-bottom: 0 !important;
    color: var(--#{$prefix}choices-item-color);
    font-weight: $font-weight-bold;
    line-height: 1;
    .react-select__multi-value__remove {
      svg {
        display: none;
      }
      height: 9px;
      width: 0.5rem;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiM2ZTc4OTEiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLXgiPjxsaW5lIHgxPSIxOCIgeTE9IjYiIHgyPSI2IiB5Mj0iMTgiPjwvbGluZT48bGluZSB4MT0iNiIgeTE9IjYiIHgyPSIxOCIgeTI9IjE4Ij48L2xpbmU+PC9zdmc+);
      background-color: transparent !important;
      padding: 0px !important;
      background-size: 11px !important;
      margin: 0;
      margin-left: 6px;
      border: none;
      background-position: -2px -1px;
      .firefox & {
        background-position: -2px 0px;
      }
      border-radius: 0 2px 2px 0 !important;
      &:hover {
        background-color: unset !important;
        color: var(--#{$prefix}gray-900) !important;
      }
    }
  }
  .react-select__multi-value__label {
    color: var(--#{$prefix}body-color) !important;
    font-size: map-get($font-sizes, '10') !important;
    padding: 0 !important;
  }
  .react-select__single-value {
    color: var(--#{$prefix}body-color) !important;
  }
}

.react-select__menu {
  background-color: $input-bg !important;
  z-index: $zindex-modal !important;
  .react-select__menu-list {
    background: var(--#{$prefix}gray-200) !important;
  }
  .react-select__option {
    color: var(--#{$prefix}gray-900) !important;
    padding: 0.5rem 1rem;
    font-size: map-get($font-sizes, '9');
    &:hover {
      background-color: var(--#{$prefix}primary) !important;
      color: var(--#{$prefix}white) !important;
    }
  }
}

.react-select__indicator-separator {
  background-color: var(--#{$prefix}react-select-indicator-bg) !important;
}
.react-select__dropdown-indicator {
  padding: 0 !important;
}

.react-select__option--is-focused {
  background-color: var(--#{$prefix}soft-primary) !important;
}
.react-select__option--is-selected {
  background-color: var(--#{$prefix}primary) !important;
  color: var(--#{$prefix}black) !important;
}
