.mapbox-container {
  position: relative;
  height: 100%;
  // min-height: 300px;
  overflow: hidden;
  .map-container {
    height: 100%;
  }
  .mapbox-control-btn {
    position: absolute;
    top: 0;
    padding: map-get($spacers, 3);
    .zoomIn,
    .zoomOut {
      @extend .btn;
      @extend .btn-phoenix-secondary;
      @extend .square-icon-btn;
      border-width: 2px;
    }
    .zoomIn {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .zoomOut {
      margin-top: -2px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
