/* -------------------------------------------------------------------------- */
/*                                 Theme                                      */
/* -------------------------------------------------------------------------- */

@import '~bootstrap/scss/_functions';
@import 'theme/functions';

@import 'user-variables';
@import 'theme/variables';

@import 'theme/maps';
@import '~bootstrap/scss/maps';

@import '~bootstrap/scss/mixins';
@import 'theme/mixins';

@import 'theme/_utilities';
@import '~bootstrap/scss/variables';
// @import '~bootstrap/scss/root';
@import 'theme/root';

/*-----------------------------------------------
|   Bootstrap Styles
-----------------------------------------------*/
@import 'bootstrap';

/*-----------------------------------------------
|   Theme Styles
-----------------------------------------------*/
@import 'theme/theme';
