/* -------------------------------------------------------------------------- */
/*                                    Flex                                    */
/* -------------------------------------------------------------------------- */

.flex-center {
  align-items: center;
  justify-content: center;
}
.flex-between-center {
  justify-content: space-between;
  align-items: center;
}
.flex-end-center {
  justify-content: flex-end;
  align-items: center;
}
.flex-between-end {
  align-items: flex-end;
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $key, $value in $gutters {
      .gap-x#{$infix}-#{$key} {
        column-gap: #{$value};
      }
      .gap-y#{$infix}-#{$key} {
        row-gap: #{$value};
      }
    }
  }
}
