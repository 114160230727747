.modal-title {
  font-size: map-get($font-sizes, 7);
  color: var(--#{$prefix}gray-800);
}

@include media-breakpoint-up(md) {
  .modal-md {
    --#{$prefix}modal-width: 768px;
  }
}

.modal-open {
  .navbar-toggler {
    margin-right: 0.6125rem !important;
  }
}
