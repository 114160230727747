/* -------------------------------------------------------------------------- */
/*                                   Leaflet                                  */
/* -------------------------------------------------------------------------- */

// @import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
// @import 'leaflet/dist/leaflet.css';

.leaflet-bar {
  box-shadow: var(--#{$prefix}box-shadow);
  a {
    text-decoration: none;
    &,
    &:hover {
      background-color: var(--#{$prefix}leaflet-bar-bg);
      color: var(--#{$prefix}body-color);
      border-bottom-color: var(--#{$prefix}border-color);
    }
    &.leaflet-disabled {
      background-color: var(--#{$prefix}gray-200);
    }
  }
}
.marker-cluster-small {
  background-color: rgba($primary, 0.2) !important;
  div {
    margin: 5px;
    background-color: var(--#{$prefix}primary) !important;
    color: var(--#{$prefix}white);
  }
}
.marker-cluster-medium {
  background-color: rgba($warning, 0.2) !important;
  div {
    margin: 5px;
    background-color: var(--#{$prefix}warning) !important;
    color: var(--#{$prefix}white);
  }
}
.marker-cluster-large {
  background-color: rgba($success, 0.2) !important;
  div {
    margin: 5px;
    background-color: var(--#{$prefix}success) !important;
    color: var(--#{$prefix}white);
  }
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background-color: var(--#{$prefix}leaflet-popup-content-wrapper-bg);
  box-shadow: $box-shadow-lg;
  border-radius: $border-radius;
}
.leaflet-popup-close-button {
  right: 3px !important;
  top: 3px !important;
}
.leaflet-marker-icon {
  height: auto !important;
  width: auto !important;
}

.leaflet-control-attribution {
  display: none;
}
.leaflet-top,
.leaflet-bottom {
  z-index: 999;
}

.leaflet-left {
  .leaflet-control {
    margin-left: $card-spacer-x;
  }
}
.leaflet-right {
  .leaflet-control {
    margin-right: $card-spacer-x;
  }
}
.leaflet-top {
  .leaflet-control {
    margin-top: $card-spacer-x;
  }
}
.leaflet-bottom {
  .leaflet-control {
    margin-bottom: $card-spacer-x;
  }
}
