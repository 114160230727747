/* -------------------------------------------------------------------------- */
/*                                    Hover                                   */
/* -------------------------------------------------------------------------- */

.hover-actions {
  display: none;
  position: absolute;
  &.show {
    display: inline-block;

    & + .hover-hide {
      // opacity: 0;

      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }
}

a[href] {
  &.bg-200 {
    @include hover-focus {
      background-color: var(--#{$prefix}gray-300) !important;
    }
  }
}

// Used in search box
.hover-primary {
  @include hover-focus {
    color: $primary !important;
  }
}
.hover-danger {
  @include hover-focus {
    color: $danger !important;
  }
}

.hover-shadow {
  @include hover-focus {
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
  }
}
.hover-actions-trigger {
  position: relative;

  // Style for email Inbox
  .inbox-link {
    color: inherit;
    text-decoration: none;
  }

  @include hover-focus {
    .hover-actions {
      z-index: 1;
      display: flex;
      &.btn {
        display: inline-block;
      }
      animation: fade 0.15s ease-in-out;
    }

    .hover-hide {
      opacity: 0;
    }

    .hover-show {
      opacity: 1 !important;
    }
    @each $breakpoint, $value in $grid-breakpoints {
      .hover-#{$breakpoint}-hide {
        @include media-breakpoint-up($breakpoint) {
          opacity: 0;
        }
      }
      .hover-#{$breakpoint}-show {
        @include media-breakpoint-up($breakpoint) {
          opacity: 1;
        }
      }
    }
  }
}

.hover-bg {
  @each $color, $value in $grays {
    &-#{$color} {
      &:hover {
        background-color: var(--#{$prefix}gray-#{$color}) !important;
      }
    }
  }
}

.hover-text {
  @each $color, $value in $grays {
    &-#{$color} {
      &:hover {
        color: var(--#{$prefix}gray-#{$color}) !important;
      }
    }
  }
  @each $color, $value in $theme-colors {
    &-#{$color} {
      &:hover {
        color: var(--#{$prefix}-#{$color}) !important;
      }
    }
  }
}

.d-toggle-container {
  .d-none-hover {
    display: block !important;
  }
  .d-block-hover {
    display: none !important;
  }
  @include hover-focus {
    .d-none-hover {
      display: none !important;
    }
    .d-block-hover {
      display: block !important;
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
