// @import 'swiper/css/bundle';
.swiper-theme-container {
  position: relative;
  .swiper {
    .swiper-wrapper {
      .swiper-slide {
        height: auto !important;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    border: 1px solid;
    border-color: var(--#{$prefix}gray-200);
    transition: opacity 0.4s ease-in-out;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    color: var(--#{$prefix}gray-900);
    background-color: var(--#{$prefix}gray-soft) !important;
    box-shadow: 0px 8px 24px -4px rgba(36, 40, 46, 0.12);
    z-index: 10;
    margin: 0;
    @include hover-focus {
      background-color: var(--#{$prefix}white);
      color: var(--#{$prefix}gray-700);
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    &::after,
    &::before {
      content: '';
    }
    &.swiper-button-disabled {
      opacity: 0;
    }
  }
  .swiper-button-prev {
    left: -1rem;
  }
  .swiper-button-next {
    right: -1rem;
  }
}
