@font-face {
    font-family: "Gilroy-medium";
    src: url("assets/fonts/gilroy-medium.ttf");
}

html {
    font-family: "Gilroy-medium", sans-serif !important;
}

html .scroll-event {
    background-color: rgba(242, 243, 246, 1)!important;
}

html.dark .scroll-event {
    background-color: #0F111A!important;
}

.w-90 {
    width: 90% !important;
}

.h-86 {
    height: 86% !important;
}

.active-box-shadow {
    /*box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;*/
    background-color: rgba(201, 201, 203, 0.27);
}

:root .badge-phoenix-primary-davr,
:root.light .badge-phoenix-primary-davr,
:root .light .badge-phoenix-primary-davr {
    --phoenix-badge-bg: rgba(88, 179, 211, 0.1);
    --phoenix-badge-color: #58B3D3;
    --phoenix-badge-border-color: #58B3D3;
}

:root .badge-phoenix-warning-davr,
:root.light .badge-phoenix-warning-davr,
:root .light .badge-phoenix-warning-davr {
    --phoenix-badge-bg: rgba(255, 165, 0, 0.1);
    --phoenix-badge-color: #FFA500!important;
    --phoenix-badge-border-color: rgba(255, 165, 0, 0.1);
}

/*:root .badge-phoenix-warning-davr,
:root.dark .badge-phoenix-warning-davr,
:root .dark .badge-phoenix-warning-davr {
    --phoenix-badge-bg: #FFA500!important;
    --phoenix-badge-color: #FFA500!important;
    --phoenix-badge-border-color: #d08801 !important;
}*/

:root .badge-phoenix-indigo-davr,
:root.light .badge-phoenix-indigo-davr,
:root .light .badge-phoenix-indigo-davr {
    --phoenix-badge-bg: rgba(89, 105, 255, 0.1);
    --phoenix-badge-color: #5969FF;
    --phoenix-badge-border-color: rgba(89, 105, 255, 0.1);
}

:root .badge-phoenix-danger-davr,
:root.light .badge-phoenix-danger-davr,
:root .light .badge-phoenix-danger-davr {
    --phoenix-badge-bg: rgba(255, 59, 48, 0.1);
    --phoenix-badge-color: #FF3B30;
    --phoenix-badge-border-color: rgba(255, 59, 48, 0.1);
}

:root .badge-phoenix-success-davr,
:root.light .badge-phoenix-success-davr,
:root .light .badge-phoenix-success-davr {
    --phoenix-badge-bg: rgba(52, 199, 89, 0.1) !important;
    --phoenix-badge-color: #34C759!important;
    --phoenix-badge-border-color: rgba(52, 199, 89, 0.1) !important;
}

/*:root .badge-phoenix-success-davr,
:root.dark .badge-phoenix-success-davr,
:root .dark .badge-phoenix-success-davr {
    --phoenix-badge-bg: #34C759!important;
    --phoenix-badge-color: white!important;
    --phoenix-badge-border-color: #09cb3a!important;
}*/

:root .badge-phoenix-secondary-davr,
:root.light .badge-phoenix-secondary-davr,
:root .light .badge-phoenix-secondary-davr {
    --phoenix-badge-bg: rgba(155, 162, 177, 0.1);
    --phoenix-badge-color: #9BA2B1;
    --phoenix-badge-border-color: rgba(155, 162, 177, 0.1);
}

/* ALERT */

.alert-davr-danger {
    color: rgba(255, 59, 48, 1) !important;
    background-color: rgba(255, 59, 48, 0.1) !important;
    border-color: rgba(255, 59, 48, 1) !important;
}

.alert-davr-primary {
    color: rgba(89, 105, 255, 1) !important;
    background-color: rgba(89, 105, 255, 0.1) !important;
    border-color: rgba(89, 105, 255, 1) !important;
}

.alert-davr-warning {
    color: rgba(255, 165, 0, 1) !important;
    background-color: rgba(255, 165, 0, 0.1) !important;
    border-color: rgba(255, 165, 0, 1) !important;
}

.bg-primary-davr {
    background-color: #58B3D3 !important;
}

.bg-primary-davr:hover, .bg-primary-davr-outline:hover, .btn-primary-davr:hover {
    background-color: #3bbdea !important;
}

.bg-primary-davr:focus, .bg-primary-davr-outline:focus, .btn-primary-davr:focus {
    background-color: #3bbdea !important;
}

.bg-secondary-davr {
    background-color: rgba(155, 162, 177, 0.1);
    color: rgba(155, 162, 177, 1);
}

.bg-secondary-davr:hover, .bg-primary-davr-outline:hover {
    background-color: rgba(155, 162, 177, 0.1);
    color: rgba(155, 162, 177, 1);
}

.bg-secondary-davr:focus, .bg-primary-davr-outline:focus {
    background-color: rgba(155, 162, 177, 0.1);
    color: rgba(155, 162, 177, 1);
}

.bg-primary-davr-outline {
    background-color: transparent;
    border: 1px solid #3bbdea !important;
    color: #3bbdea !important;
}

.bg-primary-davr-outline:hover {
    color: #fff !important;
    background-color: #3bbdea;
}

.bg-primary-davr-outline:focus {
    background-color: #3bbdea;
    color: #fff !important;
}

.bg-white:hover {
    background-color: #e7e1e1 !important;
    color: black!important;
}

.text-primary-davr {
    color: #58B3D3;
}

.main-text {
    color: #2E3035 !important;
}

html.dark .main-text {
    color: #fff !important;

}

.bg-card {
    background-color: rgba(242, 243, 246, 0.5) !important;
}

.line-color {
    color: rgba(155, 162, 177, 0.1) !important;
}

.second-text {
    color: #9BA2B1 !important;
}

.badge-phoenix {
    text-transform: none !important;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.css-1xc3v61-indicatorContainer {
    padding: 0 !important;
}

.btn-davr {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 24px;
    height: 36px;
    font-weight: 600;
    cursor: pointer;
}

.btn-back {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    cursor: pointer;
}

/* SELECT */

html.dark .css-1birlnv-control, html.dark .css-ujhf9k-control {
    background-color: #141824!important;
    border-color: #373E53!important;
}

html.dark .css-1dimb5e-singleValue, html.dark .css-ujhf9k-control {
    color: #9FA6BC!important;
}

html.dark .css-6sjtcs-control, html.dark .css-ujhf9k-control {
    background-color: #141824!important;
}

.form-select {
    height: 40px !important;
}

.form-floating > .form-select {
    padding-top: 14px !important;
    padding-bottom: 0 !important;
}

.dQGTVK, .gctbcG {
    margin-top: 0 !important;
    width: 100% !important;
}

.select > div:first-child {
    margin-top: 0 !important;
    width: 100%;
}

.css-olqui2-singleValue, .css-1dimb5e-singleValue {
    margin-top: 15px !important;
}

.currency-select .css-olqui2-singleValue, .currency-select .css-1dimb5e-singleValue {
    margin-top: 0 !important;
}

.currency-select .css-13cymwt-control {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.currency-select .css-b62m3t-container, .currency-select .css-13cymwt-control, .currency-select > div {
    height: 100% !important;
}

.form-control {
    border-radius: 8px !important;
}

.form-floating .form-control {
    display: flex;
    align-items: center;
    height: 40px;
}

.form-floating label {
    top: -5px !important;
    text-transform: none !important;
    font-size: 14px !important;
    font-weight: 600 !important;

}

.form-label {
    text-transform: none !important;
}

/* DATE PICKER */

.react-datepicker-wrapper input {
    padding-top: 14px !important;
    padding-bottom: 4px !important;

}

.react-datepicker {
    display: flex !important;
}

/* TABLE */

.table-view thead tr, .table-view thead tr th {
    /*border-top: 1px solid rgba(128, 128, 128, 0.09) !important;*/
    border-color: rgba(128, 128, 128, 0.09) !important;
}

.table-view thead tr th:first-child, .table-view tbody tr td:first-child {
    border-left: 0 !important;
}

.table-view thead tr th:last-child, .table-view tbody tr td:last-child {
    border-right: 0 !important;
}

.view-table thead tr th {
    position: relative;
}

.view-table thead tr th:after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 50%;
    background-color: rgba(169, 169, 169, 0.43);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.view-table thead tr th:last-child:after {
    display: none;
}

.view-table thead {
    padding: 0 10px !important;
}

/* Custom scrollbar for WebKit browsers (Chrome, Safari) */
.table-responsive::-webkit-scrollbar {
    width: 12px; /* Width of the vertical scrollbar */
    height: 5px; /* Height of the horizontal scrollbar */
}

.table-responsive::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background color of the scrollbar track */
    border-radius: 10px; /* Rounding corners of the track */
}

.table-responsive::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.51); /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounding corners of the thumb */
}

.table-responsive::-webkit-scrollbar-thumb:hover {
    background: rgba(136, 136, 136, 0.51); /* Color of the scrollbar thumb on hover */
}

.border-bottom {
    border-color: rgba(169, 169, 169, 0.43) !important;
}

.sort {
    white-space: nowrap;
    cursor: pointer;

    &::after {
        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgNyA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi41MjU1NiAzLjVDNi45NDkzNyAzLjUgNy4xNjEyOCAzLjA2MjUgNi44NTUxOSAyLjc5MTY3TDMuODQxNCAwLjEyNUMzLjY1MzA0IC0wLjA0MTY2NjcgMy4zNDY5NiAtMC4wNDE2NjY3IDMuMTU4NTkgMC4xMjVMMC4xNDQ4MDkgMi43OTE2N0MtMC4xNjEyNzkgMy4wNjI1IDAuMDUwNjI3OSAzLjUgMC40NzQ0NDEgMy41SDYuNTI1NTZaIiBmaWxsPSIjQjZDMkQyIi8+CjxwYXRoIGQ9Ik0wLjQ3NDQ0MiA1LjVDMC4wNTA2MjgyIDUuNSAtMC4xNjEyNzkgNS45Mzc1IDAuMTQ0ODA5IDYuMjA4MzNMMy4xNTg1OSA4Ljg3NUMzLjM0Njk2IDkuMDQxNjcgMy42NTMwNCA5LjA0MTY3IDMuODQxNDEgOC44NzVMNi44NTUxOSA2LjIwODMzQzcuMTYxMjggNS45Mzc1IDYuOTQ5MzcgNS41IDYuNTI1NTYgNS41TDAuNDc0NDQyIDUuNVoiIGZpbGw9IiNCNkMyRDIiLz4KPC9zdmc+Cg==');
        margin-left: 0.25rem;
    }
}

.sort.desc {
    &::after {
        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgNyA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC40NzQ0NDIgMC41MDAwMDFDMC4wNTA2MjgyIDAuNTAwMDAxIC0wLjE2MTI3OSAwLjkzNzUgMC4xNDQ4MDkgMS4yMDgzM0wzLjE1ODU5IDMuODc1QzMuMzQ2OTYgNC4wNDE2NyAzLjY1MzA0IDQuMDQxNjcgMy44NDE0MSAzLjg3NUw2Ljg1NTE5IDEuMjA4MzNDNy4xNjEyOCAwLjkzNzUwMSA2Ljk0OTM3IDAuNTAwMDAxIDYuNTI1NTYgMC41MDAwMDFMMC40NzQ0NDIgMC41MDAwMDFaIiBmaWxsPSIjQjZDMkQyIi8+Cjwvc3ZnPgo=') !important;
        margin-top: -1px !important;
    }
}

.sort.asc {
    &::after {
        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgNyA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi41MjU1NiAzLjVDNi45NDkzNyAzLjUgNy4xNjEyOCAzLjA2MjUgNi44NTUxOSAyLjc5MTY3TDMuODQxNCAwLjEyNUMzLjY1MzA0IC0wLjA0MTY2NjcgMy4zNDY5NiAtMC4wNDE2NjY3IDMuMTU4NTkgMC4xMjVMMC4xNDQ4MDkgMi43OTE2N0MtMC4xNjEyNzkgMy4wNjI1IDAuMDUwNjI3OSAzLjUgMC40NzQ0NDEgMy41SDYuNTI1NTZaIiBmaWxsPSIjQjZDMkQyIi8+Cjwvc3ZnPgo=') !important;
        margin-top: -1px !important;
    }
}

.border-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.border-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.nav-underline .nav-link.active {
    color: #58B3D3 !important;
    border-bottom: 2px solid #58B3D3 !important;
}

/* CAROUSEL */
.carousel-inner {
    height: 100% !important;
}


.authBackground .carousel-indicators {
    top: 3.2rem;
    left: 1.8rem;
    right: unset;
    bottom: unset;
    margin: 0 !important;
}

.authBackground .carousel-indicators [data-bs-target] {
    background: white;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: none;
}

.authBackground .carousel-indicators .active {
    width: 60px !important;
    border-radius: 15px !important;
}

.authBackground .theme-slider .carousel-control-prev {
    z-index: 2;
    opacity: 1;
    left: unset;
    right: 4.75rem;
    top: 2.5rem;
    background: white;
}

.authBackground .theme-slider .carousel-control-next {
    opacity: 1;
    z-index: 2;
    right: 1.25rem;
    top: 2.5rem;
    background: white;
}

.authBackground .theme-slider:hover .carousel-control-prev,
.authBackground .theme-slider:hover .carousel-control-next,
.authBackground .theme-slider:focus .carousel-control-prev,
.authBackground .theme-slider:focus .carousel-control-next {
    opacity: 1 !important;
}

.auth {
    background-image: url("assets/img/Vector 26 (2).png"), url("assets/img/Vector 27 (1).png");
    background-repeat: no-repeat;
    background-position: right 0;
    background-color: #58B3D3;
}

.authBackground {
    background: url("assets/img/AuthBackground.svg") center no-repeat;
    background-size: cover;
}

.authMobileBackground {
    background: url("assets/img/AuthMobileBackground.svg") center no-repeat;
    background-size: cover;
}

.authEImzoBackground {
    background: url("assets/img/AuthEImzoBackground.svg") center no-repeat;
    background-size: cover;
}

.card-bg-img {
    background-image: url("assets/img/Billing.png"), url("assets/img/Billing2.png");
    background-repeat: no-repeat;
    background-position: right 0;
}


.grid-row-auth-eimzo{
    grid-row: 8 / span 2;
}
.grid-row-auth-form{
    grid-row: 1 / span 7;
}

.bg-main-davr {
    background-color: #F2F3F6 !important;
}


/* DROPDOWN */

.dropdown-toggle::after {
    content: url("assets/img/Checvron-bottom.png");
    border-top: none !important;
}

#bg-nested-dropdown {
    --phoenix-btn-padding-x: 10px;
}


.dropdown-line {
    position: relative;
}

.dropdown-line:after {
    content: "";
    width: 1px;
    height: 36px;
    background: white;
    position: absolute;
    right: -15px;
    top: -10px
}

/* MODAL */
.davr-modal .modal-content {
    background: #F2F3F6;
    border-radius: 32px;
}

.davr-modal .modal-header {
    border-bottom: none;
}

.davr-modal .modal-footer {
    border-top: none;
}

html.dark .davr-modal .modal-content {
    background: rgb(104, 123, 152) !important;
}

.davr-modal .btn-davr-modal-close {
    padding: 16px !important;
    border-radius: 16px;
}

.text-truncate-ellipsis {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
}

/* MODAL */

.modal-header, .modal-body {
    /*background-color: rgba(242, 243, 246, 1)!important;*/
}

.btn-primary-davr {
    background-color: #58B3D3;
    color: #fff;
    border: none;
    border-radius: 50px;
    padding: 8px 24px;
    font-weight: 600;
    font-size: 16px;
}

.btn-white-davr {
    background-color: #fff;
    color: #000;
    border-radius: 50px;
    padding: 8px 24px;
    font-weight: 600;
    font-size: 16px;
}

html.dark .btn-white-davr {
    background-color: #141824;
    color: #fff;
}

.simplebar-content-wrapper {
    overflow: initial !important;
}

.simplebar-scrollbar {
    display: none !important;
}

/*RANGE SLIDER*/
.range-slider__tooltip__label {
    text-align: center!important;
}

.simplebar-scrollable-x{
    overflow-x: auto !important;
}

.grid-cols-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.grid-cols-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

#smsForm .form-control {
    text-align: center;
    padding: 5px 10px;
}


@media (max-width: 768px) {
    .grid-cols-3 {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 576px) {
    #smsForm .form-control {
        padding: 3px; /* Kichik ekranlar uchun paddingni kamaytirish */
    }

    .grid-cols-3 {
        grid-template-columns: repeat(1, 1fr);
    }
}

.position-sticky {
    z-index: 999!important;
}

.h-mobile-app {
    height: auto !important;
}

@media (min-width: 1540px) {
    .h-mobile-app {
        height: 36px !important;
    }
}

@media (max-width: 992px) {
    .grid-row-auth-form{
        grid-row: 1 / span 6;
    }
    .grid-row-auth-eimzo{
        grid-row: 7 / span 3;
    }

    .rounded-5{
        border-radius: 24px!important;
    }
}

@media (max-width: 768px) {
    .grid-row-auth-form{
        grid-row: 1 / span 9;
    }
    .grid-row-auth-eimzo{
        display: none;
    }
}

.certificate  .css-1dimb5e-singleValue, .certificate .css-olqui2-singleValue {
    margin-top: 0!important;
}

.sticky-column {
    position: sticky!important;
    right: 0;
    background-color: white!important;
    z-index: 1!important;
}


/* SWITCH */

.switch-container {
    display: flex;
    align-items: center;
    /*justify-content: space-between;*/
}

/* Стили для метки */
.switch-label {
    margin: 0;
    font-family: Arial, sans-serif;
    font-size: 0.8rem; /* Размер шрифта */
    /*color: #333; !* Цвет текста *!*/
}

/* Контейнер для переключателя */
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
}

/* Скрытие стандартного чекбокса */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* Стиль переключателя */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #F2F3F6;
    transition: .4s;
    border-radius: 34px;
}

/* Стиль круга внутри переключателя */
.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: #9BA2B1;
    transition: .4s;
    border-radius: 50%;
}

/* Изменение фона переключателя при включении */
input:checked + .slider:before {
    background-color: #58B3D3;
}

/* Перемещение круга при включении переключателя */
input:checked + .slider:before {
    transform: translateX(16px);
}
